import React, { useState, useEffect } from "react";
import { Model } from "survey-core";
import "survey-core/defaultV2.css";
import axios from "axios";
import * as SurveyAnalytics from "survey-analytics";
import "survey-analytics/survey.analytics.css";


function SurveyAnalyticsComponent() {
	const [loading, setLoading] = useState(false);
	const surveyId = window.location.href.split("/").pop();
	useEffect(() => {
		if (!surveyId) return;
		getById(surveyId);
	}, []);

	const getById = id => {
		setLoading(true);
		axios
			.get(`${process.env.REACT_APP_API_URL}/surveys/${id}`)
			.then(result => {
				if (result && result.data && result.data.definition) {
					const survey = new Model(result.data.definition);
					const surveyResultNode =
						document.getElementById("surveyResult");
					surveyResultNode.innerHTML = "";
					const answers = [];
					result.data.answers.map(k =>
						answers.push(JSON.parse(k.definition))
					);
					const visPanel = new SurveyAnalytics.VisualizationPanel(
						survey.getAllQuestions(),
						answers,
						{ labelTruncateLength: 27, allowHideQuestions: true }
					);
					visPanel.showHeader = true;
					visPanel.render(surveyResultNode);
					setLoading(false);
				}
			});
	};

	return (
		<>
			{loading && (
				<div className="text-center" style={{ marginTop: 50 }}>
					Loading...
				</div>
			)}
			<div id="surveyResult"></div>
		</>
	);
}

export default SurveyAnalyticsComponent;
