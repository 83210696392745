import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

function ViewAnswers() {
	const [redirectUrl, setRedirectUrl] = useState(null);
	const [survey, setSurvey] = useState(null);
	const surveyId = window.location.href.split("/").pop();

	useEffect(() => {
		reload();
	}, []);

	const reload = () => {
		if (!surveyId) return;
		axios
			.get(`${process.env.REACT_APP_API_URL}/surveys/${surveyId}`)
			.then(result => {
				setSurvey(result.data);
			});
	};

	const renderList = () => {
		if (!survey || !survey.answers) return;
		if (survey.answers.length === 0)
			return (
				<tr>
					<td colSpan={2}>No answers found.</td>
				</tr>
			);

		return survey.answers.map(row => {
			return (
				<tr key={row._id}>
					<td>{moment(row.date).fromNow()}</td>
					<td className="text-center">
						<textarea className="form-control">
							{row.definition}
						</textarea>
					</td>
				</tr>
			);
		});
	};

	if (survey)
		return (
			<>
				{redirectUrl && <Navigate push to={redirectUrl} />}
				<div className="container">
					<div className="row mt-20">
						<div className="col">
							<div className="display-6">
								Answers for the survey: "{survey.title}"
							</div>
						</div>
					</div>
					<div className="row mt-20">
						<div className="col">
							<table className="table table-striped table-bordered table-hover table-sm w-100">
								<thead>
									<tr>
										<th>Date</th>
										<th className="text-center">Answers</th>
									</tr>
								</thead>
								<tbody>{renderList()}</tbody>
							</table>
						</div>
					</div>
				</div>
			</>
		);
}

export default ViewAnswers;
