import logo from '../assets/Logo-azul.png';

import { Route, Routes, BrowserRouter, Link } from "react-router-dom";

function Header() {
	return (
		<nav className="navbar navbar-dark bg-dark" style={{ paddingLeft: 20 }}>
			<Link className="navbar-brand" to="/">
				{/* Survey Creator Keos */}
				<img src={logo} alt="Survey Creator" />
			</Link>
		</nav>
	);
}

export default Header;
