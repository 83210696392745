import "./App.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import SurveyComponent from "./components/SurveyComponent";
import SurveyRunner from "./components/SurveyRunner";
import SurveyAnalytics from "./components/SurveyAnalytics";
import ViewAnswers from "./components/ViewAnswers";
import List from "./components/List";
import Header from "./components/Header";

function App() {
	return (
		<BrowserRouter>
			<Header />
			<Routes>
				<Route exact path="/" element={<List />} />
				<Route
					exact
					path="/create/:surveyId"
					element={<SurveyComponent />}
				/>
				<Route exact path="/run/:surveyId" element={<SurveyRunner />} />
				<Route
					exact
					path="/answers/:surveyId"
					element={<ViewAnswers />}
				/>
				<Route
					exact
					path="/analytics/:surveyId"
					element={<SurveyAnalytics />}
				/>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
