import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

function List() {
	const [redirectUrl, setRedirectUrl] = useState(null);
	const [keyword, setKeyword] = useState("");
	const [list, setList] = useState([]);
	const userId = process.env.REACT_APP_USER_ID;
	const companyId = process.env.REACT_APP_COMPANY_ID;

	useEffect(() => {
		reload();
	}, [keyword]);

	const reload = () => {
		axios
			.post(`${process.env.REACT_APP_API_URL}/surveys/mine`, {
				userId: userId,
				companyId: companyId,
				keyword: keyword
			})
			.then(result => {
				setList(result.data);
			});
	};

	const renderList = () => {
		if (list.length === 0)
			return (
				<tr>
					<td colSpan={4}>No surveys found.</td>
				</tr>
			);

		return list.map(row => {
			return (
				<tr key={row._id}>
					<td>{row.title}</td>
					<td>{row.userId}</td>
					<td>{moment(row.date).fromNow()}</td>
					<td className="text-center">{row.answers.length || 0}</td>
					<td className="text-center">
						<button
							className="btn btn-sm btn-light"
							style={{ marginLeft: 2 }}
							onClick={e => handleRun(row._id)}
						>
							Run
						</button>
						<button
							className="btn btn-sm btn-light"
							style={{ marginLeft: 2 }}
							onClick={e => handleEdit(row._id)}
						>
							Edit
						</button>
						<button
							className="btn btn-sm btn-light"
							style={{ marginLeft: 2 }}
							onClick={e => handleShowAnswers(row._id)}
						>
							View Answers
						</button>
						<button
							className="btn btn-sm btn-light"
							style={{ marginLeft: 2 }}
							onClick={e => handleShowAnalytics(row._id)}
						>
							View Analytics
						</button>
						<button
							className="btn btn-sm btn-outline-danger"
							style={{ marginLeft: 2 }}
							onClick={e => handleDelete(row._id)}
						>
							Delete
						</button>
					</td>
				</tr>
			);
		});
	};

	const handleEdit = id => {
		setRedirectUrl(`/create/${id}`);
	};

	const handleRun = id => {
		setRedirectUrl(`/run/${id}`);
	};

	const handleShowAnswers = id => {
		setRedirectUrl(`/answers/${id}`);
	};

	const handleShowAnalytics = id => {
		setRedirectUrl(`/analytics/${id}`);
	};

	const handleDelete = id => {
		if (window.confirm(`Are you sure you want to delete this survey?`)) {
			axios
				.delete(`${process.env.REACT_APP_API_URL}/surveys/${id}`)
				.then(result => {
					reload();
				});
		}
	};

	const handleCreate = e => {
		e.preventDefault();
		axios
			.post(`${process.env.REACT_APP_API_URL}/surveys/`, {
				userId: userId,
				companyId: companyId,
				definition: ``,
				date: moment().format(),
				title: ``
			})
			.then(result => {
				setRedirectUrl(`/create/${result.data._id}`);
			});
	};

	return (
		<>
			{redirectUrl && <Navigate push to={redirectUrl} />}
			<div className="container">
				<div className="row mt-20">
					<div className="col">
						<div className="display-6">Surveys</div>
					</div>
					<div className="col text-end">
						<button
							className="btn btn-dark btn-sm"
							style={{ marginTop: 15 }}
							onClick={handleCreate}
						>
							Create
						</button>
					</div>
				</div>
				<div className="row mt-20">
					<div className="col">
						<input
							type="text"
							className="form-control"
							placeholder="Type to search by title..."
							value={keyword}
							onChange={e => setKeyword(e.target.value)}
						/>
					</div>
				</div>
				<div className="row mt-20">
					<div className="col">
						<table className="table table-striped table-bordered table-hover table-sm w-100">
							<thead>
								<tr>
									<th>Title</th>
									<th>User ID</th>
									<th>Created</th>
									<th className="text-center">Answers</th>
									<th className="text-center">Options</th>
								</tr>
							</thead>
							<tbody>{renderList()}</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);
}

export default List;
