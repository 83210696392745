import React, { useEffect, useState } from "react";
import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";
import { SurveyPDF } from "survey-pdf";
import moment from "moment";
import axios from "axios";
import "survey-core/survey.i18n.js";
import "survey-creator-core/survey-creator-core.i18n.js";
import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.css";

function SurveyComponent() {
	const userId = process.env.REACT_APP_USER_ID;
	const companyId = process.env.REACT_APP_COMPANY_ID;
	const surveyId = window.location.href.split("/").pop();
	const defaultJSON = {
		pages: [{ name: "page1", elements: [{ type: "text", name: "q1" }] }]
	};
	const [survey, setSurvey] = useState(defaultJSON);

	useEffect(() => {
		if (!surveyId) return;
		getById(surveyId);
	}, [surveyId]);

	const getById = id => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/surveys/${id}`)
			.then(result => {
				setSurvey(result.data);
			});
	};

	const creatorOptions = {};
	const creator = new SurveyCreator(creatorOptions);

	creator.isAutoSave = true;
	creator.showState = true;

	creator.saveSurveyFunc = function (saveNo, callback) {
		if (!surveyId) return;
		axios
			.put(`${process.env.REACT_APP_API_URL}/surveys/${surveyId}`, {
				userId: userId,
				companyId: companyId,
				definition: creator.text,
				title: JSON.parse(creator.text).title || ``
			})
			.then(result => {});

		callback(saveNo, true);
	};

	creator.JSON =
		survey && survey.definition && survey.definition.length > 0
			? JSON.parse(survey.definition)
			: defaultJSON;

	return survey ? <SurveyCreatorComponent creator={creator} /> : <></>;
}

export default SurveyComponent;
