import React, { useState, useEffect } from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.css";
import moment from "moment";
import axios from "axios";
import { Link, Navigate } from "react-router-dom";
import { SurveyPDF } from "survey-pdf";

function SurveyRunnder() {
	const [redirectUrl, setRedirectUrl] = useState(null);
	const surveyId = window.location.href.split("/").pop();
	const [surveyFromDb, setSurveyFromDb] = useState(null);

	useEffect(() => {
		if (!surveyId) return;
		getById(surveyId);
	}, [surveyId]);

	const getById = id => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/surveys/${id}`)
			.then(result => {
				setSurveyFromDb(result.data);
			});
	};

	// https://surveyjs.io/Documentation/Pdf-Export?id=Options-in-Constructor
	const exportToPdfOptions = {
		fontSize: 12,
		format: "letter",
		textFieldRenderAs: "multiLine",
		applyImageFit: true
	};

	if (
		surveyFromDb &&
		surveyFromDb.definition &&
		surveyFromDb.definition.length > 0
	) {
		const savePdf = function (surveyData) {
			const surveyPdf = new SurveyPDF(
				surveyFromDb.definition,
				exportToPdfOptions
			);
			surveyPdf.data = surveyData;
			surveyPdf.save();
		};
		const survey = new Model(surveyFromDb.definition);
		survey.onComplete.add(function (result) {
			axios
				.post(`${process.env.REACT_APP_API_URL}/answers/`, {
					surveyId: surveyId,
					definition: JSON.stringify(result.data),
					date: moment().format()
				})
				.then(result => {
					setRedirectUrl(`/`);
				});
		});
		survey.addNavigationItem({
			id: "pdf-export",
			title: "Save as PDF",
			action: () => savePdf(survey.data)
		});

		return (
			<>
				{redirectUrl && <Navigate push to={redirectUrl} />}
				<Survey model={survey} />
			</>
		);
	}
}

export default SurveyRunnder;
